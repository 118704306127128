import React, { Component } from 'react';
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

import ProjectPostMobile from './ProjectPostMobile';
import { addUserProposed, addToActivityLog } from '../../../../../store/actions/projectActions'

import './mobile.css';

class ProjectGroupMobile extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
          signupCount: 0,
          signupMax : props.project.connectedProjects.length + 1,
  
          signupAllButton: {
              message: "Signup for All " + props.project.client,
              variant: '',
              type: '',
              isDisabled: true,
              isVisible: false
            },
  
          groupRefs: [props.project.refLocation]
      }
  
      props.project.connectedProjects.forEach(refLocation => {
        const projectData = props.projects[refLocation.id];
        if (projectData && (projectData.phase === 'scheduled' || projectData.phase === 'confirmed')) {
            this.state.groupRefs.push(refLocation);
        }
    })
  
    let shouldItDisable = true;
    this.state.groupRefs.forEach(ref => {
        const projectData = props.projects[ref.id];
        if (projectData) {
            const proposedList = projectData[`proposed${props.userRole.capitalize}List`];
            if (proposedList && !Object.keys(proposedList).includes(props.auth.uid)) {
                shouldItDisable = false;
            }
        }
    });
    
    this.state.signupAllButton.isDisabled = shouldItDisable
    }
  
    callbackFunction = (countUp) => {
        if (countUp) {
          this.setState({signupCount: this.state.signupCount + 1}, this.checkIfMax)
        } else {
          this.setState({signupCount: this.state.signupCount - 1})
        }
    }
  
    checkIfMax = () => {
      if (this.state.signupCount === this.state.signupMax) {
          var buttonState = {...this.state.signUpAllButton}
          buttonState.isDisabled = true
          buttonState.message = "Shifts Requested"
          return this.setState({signupAllButton: buttonState})
      }
    }
  
    handleSignupAll = (userID, userRole) => {
        this.state.groupRefs.forEach(ref => {
            this.props.addUserProposed(ref, userID, userRole)
            this.props.addToActivityLog(ref, `${userRole.lower} signup`, userID, userID, userRole);
        })
        var buttonState = {...this.state.signupAllButton}
        buttonState.isDisabled = true
        buttonState.message = 'Shift Requested'
        this.setState(() => ({signupAllButton: buttonState}))
    }
  

    // "array" is used because splice was used to create removedEntries, which returns an array of arrays (console.log removedEntries to see) //

    render() {
        const { auth, profile, project, removedEntries, userRole} = this.props;
    
        const groupRefs = this.state.groupRefs
        if (groupRefs.length > 1) {
            return (
                <div>
                    <Container fluid>
                        <div className="bigButton">
                            <Button
                                variant="primary"
                                size="lg"
                                disabled={this.state.signupAllButton.isDisabled}
                                onClick={() => {this.handleSignupAll(auth.uid, userRole)}}
                            >
                                {this.state.signupAllButton.message}
                            </Button>
                        </div>
    
                        <Row>
                            <Col xs={12}>
                                <ProjectPostMobile
                                    auth={auth}
                                    profile={profile}
                                    project={project}
                                    parentCallback={this.callbackFunction}
                                    disableAll={this.state.signupAllButton.isDisabled}
                                    userRole={userRole}
                                />
                            </Col>
                            
    
                            {groupRefs.filter(Boolean).map((projectRef) => {
                                return removedEntries.filter(Boolean).map((array) => {          // see above note
                                    if (array[0].refLocation.id === projectRef.id) {
                                        return (
                                            <Col key={array[0].refLocation.id} xs={12}>
                                                <ProjectPostMobile
                                                    auth={auth}
                                                    profile={profile}
                                                    project={array[0]}
                                                    parentCallback={this.callbackFunction}
                                                    disableAll={this.state.signupAllButton.isDisabled}
                                                    userRole={userRole}
                                                />
                                            </Col>     
                                        )
                                    } 
                                })
                            })}
                        </Row>
                        <hr className="hr"/>
    
                    </Container>
                </div>
            )
        } else {
            return (
                <div>
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <ProjectPostMobile auth={auth} profile={profile} project={project} parentCallback={this.callbackFunction} userRole={userRole} />
                            </Col>
                        </Row>
                        <hr className="hr"/>
                    </Container>
                </div>
            )
        }    
      }   
    }
    
    const mapDispatchToProps = dispatch => {
        return {
            addUserProposed: (refLocation, userID, userRole) => dispatch(addUserProposed(refLocation, userID, userRole)),
            addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
        }
      }
    
    export default connect(null, mapDispatchToProps)(ProjectGroupMobile);