import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ContactInfo from './ContactInfo';
import Writeups from './Writeups/Writeups';
import WorkplaceNoticesMobile from './WorkplaceNoticesMobile';
import Loading from '../assets/Loading.gif';
import './UserInfoProfile.css';

class UserInfoProfileMobile extends Component {
  state = {};

  render() {
    const { auth, profile, users, noticesWithUrls } = this.props

    if (auth && profile.firstName) {
      return(
        <Container>
          <Row>
            <ContactInfo profile={profile} />
          </Row>

          <Row>
            <Writeups auth={auth} profile={profile} users={users}/>
          </Row>

          <Row>
            <WorkplaceNoticesMobile noticesWithUrls={noticesWithUrls} />
          </Row>
        </Container>
      )
    } else {
      return <img style={{marginTop: 20}} className="LoadingWheel" src={Loading} alt="loading..." />
    }
  }
}

export default UserInfoProfileMobile;