import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import ModalAddProjectToClient from './ModalAddProjectToClient';
import ModalResetProjectsForClient from './ModalResetProjectsForClient';

const monthOrder = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh', // Added max height
        overflowY: 'auto', // Enable vertical scrolling
    },
    overlay: { zIndex: 1000 }
};

function formatDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

function ModalProjectsForClients(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };

    function closeModal() {
        setIsOpen(false);
    };

    const { clientID, clientName, projects, configuration } = props;

    const config = configuration || { scheme: 'https', host: 'murphymckay.online' };
    const groupedProjects = projects ? Object.values(projects).reduce((acc, projectData) => {
        const date = projectData.date.toDate();
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
    
        // Initialize the nested structure for clientName, year, and month if it doesn't exist
        if (!acc[clientName]) {
            acc[clientName] = {};
        }
    
        if (!acc[clientName][year]) {
            acc[clientName][year] = {};
        }
    
        if (!acc[clientName][year][month]) {
            acc[clientName][year][month] = [];
        }
    
        // Add the projectData to the appropriate year/month under the clientName
        acc[clientName][year][month].push(projectData);
    
        return acc;
    }, {}) : {};

    
    const hasProjects = Object.keys(groupedProjects).length > 0;

    return (
        <>
            <Button onClick={openModal} style={{ marginLeft: 0 }} variant="success">
                Projects
            </Button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Project Scope Modal"
            >
                <h2 style={{ display: 'flex', justifyContent: 'space-between', color: '#f00' }}>
                    {`Projects`}
                    <ModalAddProjectToClient clientID={clientID} />
                    <ModalResetProjectsForClient clientID={clientID} />
                </h2>

                <div>
                {hasProjects ? (
                    Object.entries(groupedProjects).map(([clientName, years]) => (
                        <div key={clientName} style={{ marginBottom: '20px' }}>
                            <h3>{clientName}</h3>
                            {Object.entries(years).map(([year, months]) => (
                                <div key={year} style={{ marginBottom: '10px' }}>
                                    <h4 style={{ textAlign: 'center' }}>{year}</h4>
                                    {Object.entries(months)
                                        .sort((a, b) => monthOrder.indexOf(a[0]) - monthOrder.indexOf(b[0]))
                                        .map(([month, projects]) => (
                                        <div key={month} style={{ marginBottom: '10px' }}>
                                            <h5>{month}</h5>
                                            <ul>
                                                {projects
                                                    .sort((a, b) => a.date.toDate() - b.date.toDate()) // Sort projects by date
                                                    .map((projectData, index) => {
                                                        const date = projectData.date.toDate();
                                                        const dayWithSuffix = formatDayWithSuffix(date.getDate());
                                                        const projectURL = `${config.scheme}://${config.host}/${projectData.refLocation.path}`;

                                                        return (
                                                            <li key={index} style={{ marginBottom: '10px' }}>
                                                                <span>{`${dayWithSuffix} - `}</span>
                                                                <a href={projectURL} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '0', margin: '0' }}>
                                                                    {projectData.name}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <p>No projects available.</p>
                )}
                </div>
            </Modal>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        projects: state.firestore.data.projects,
        configuration: state.firestore.data.configuration,
    };
};

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect((props) => {
        return [
            {
                collection: 'clients',
                doc: props.clientID,
                subcollections: [{ collection: 'projects' }],
                storeAs: 'projects'
            },
            {
                collection: 'projects',
                doc: 'configuration',
                storeAs: 'configuration'
            },
        ];
    })
)(ModalProjectsForClients);
