import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import { noticesAccepted } from '../../../../store/actions/userActions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh',
    overflowY: 'auto',
    width: '90%',
    maxWidth: '800px',
  },
  overlay: { zIndex: 1000 },
};

function LoginNotice(props) {
  const [modalIsOpen, setIsOpen] = useState(true);
  const contentRef = useRef(null);
  const { notices } = props;

  useEffect(() => {
    if (notices.length === 0) {
      closeModal();
    }
  }, [notices]);

  function handleAccept(id) {
    props.noticesAccepted(id);
    closeModal();
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Important Notices"
        shouldCloseOnOverlayClick={false}
      >
        <h2 style={{ textAlign: 'center' }}>Workplace Notice</h2>
        
        <div
          ref={contentRef}
          style={{ maxHeight: '60vh', overflowY: 'auto', marginBottom: '10px' }}
        >
          {notices.length > 0 ? (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {notices.map((notice, index) => {
                const isPdf = notice.url && (notice.url.endsWith('.pdf') || notice.name.endsWith('.pdf'));

                return (
                  <li key={index} style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
                      {isPdf ? (
                        <>
                          <object
                            data={notice.url}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                            style={{ border: 'none' }}
                          >
                            <p>Your browser does not support PDFs. 
                              <a href={notice.url} target="_blank" rel="noopener noreferrer">Download the PDF</a> instead.
                            </p>
                          </object>
                          <div style={{ marginTop: '10px' }}>
                            <a href={notice.url} target="_blank" rel="noopener noreferrer">Open PDF in New Tab</a>
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={notice.url}
                            alt="Notice"
                            style={{ maxWidth: '80%', height: 'auto', borderRadius: '8px' }}
                          />
                          <div style={{ marginTop: '10px' }}>
                            <a href={notice.url} target="_blank" rel="noopener noreferrer">View Image in New Tab</a>
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
          <Button
            variant="primary"
            size="sm"
            onClick={() => handleAccept(props.id)}
          >
            I Acknowledge
          </Button>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    noticesAccepted: (id) => dispatch(noticesAccepted(id)),
  };
};

export default connect(null, mapDispatchToProps)(LoginNotice);
