import React from 'react';
import { ListGroup, Button, Spinner } from 'react-bootstrap';

const NoticesList = ({
    unpublishedFiles,
    publishedFiles,
    onDelete,
    onPublish,
    onUnpublish,
    deletingFileId,
    publishingFileId,
    unpublishingFileId,
}) => (
    <>
        <h5 className="mt-4">Unpublished Notices</h5>
        {unpublishedFiles.length > 0 ? (
            <ListGroup>
                {unpublishedFiles.map(file => (
                    <ListGroup.Item key={file.name} className="d-flex flex-column align-items-center">
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                            <span className="text-center mb-2">{file.name}</span>
                        </a>
                        <div className="d-flex justify-content-between w-100">
                            <Button variant="danger" size="sm" onClick={() => onDelete(file)} style={{ padding: '0.2rem 0.5rem' }}>Delete</Button>
                            <Button variant="success" size="sm" onClick={() => onPublish(file)} style={{ padding: '0.2rem 0.5rem' }}>Publish</Button>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        ) : (
            <p>No unpublished notices available</p>
        )}

        <h5 className="mt-4">Published Notices</h5>
        {publishedFiles.length > 0 ? (
            <ListGroup>
                {publishedFiles.map(file => (
                    <ListGroup.Item key={file.name} className="d-flex flex-column align-items-center">
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                            <span className="text-center mb-2">{file.name}</span>
                        </a>
                        <div className="d-flex justify-content-between w-100">
                            {/* Conditionally render spinner or unpublish button */}
                            {deletingFileId === file.id || publishingFileId === file.id ? (
                                <Spinner animation="border" role="status" size="sm" />
                            ) : (
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => onUnpublish(file)}
                                    style={{ padding: '0.2rem 0.5rem' }}
                                >
                                    Unpublish
                                </Button>
                            )}
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        ) : (
            <p>No notices published</p>
        )}
    </>
);

export default NoticesList;
