import { v4 as uuidv4 } from 'uuid';

export const addProjectToScope = (userID, projectPath) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Reference to the user's document in Firestore
            const userDocRef = firestore.collection('users').doc(userID);

            // Add the projectPath as a reference to the projectScope array
            await userDocRef.update({
                projectScope: firestore.FieldValue.arrayUnion(firestore.doc(projectPath))
            });

            dispatch({ type: 'UPDATE_PROJECT_SCOPE_SUCCESS', userID });

        } catch (err) {
            console.error("Error updating project scope:", err);
            dispatch({ type: 'UPDATE_PROJECT_SCOPE_ERROR', err });
        }
    };
};

export const removeProjectFromScope = (userID, projectID) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Reference to the user's document in Firestore
            const userDocRef = firestore.collection('users').doc(userID);

            // Fetch the user's document to get the current projectScope array
            const userDoc = await userDocRef.get();
            if (!userDoc.exists) {
                throw new Error(`User document with ID ${userID} not found.`);
            }

            const projectScope = userDoc.data().projectScope || [];

            // Find the reference that matches the projectID
            const projectRefToRemove = projectScope.find(ref => ref.path.endsWith(projectID));
            if (!projectRefToRemove) {
                throw new Error(`Project with ID ${projectID} not found in projectScope.`);
            }

            // Remove the matching project reference from the projectScope array
            await userDocRef.update({
                projectScope: firestore.FieldValue.arrayRemove(projectRefToRemove)
            });

            dispatch({ type: 'REMOVE_PROJECT_SCOPE_SUCCESS', userID, projectID });

        } catch (err) {
            console.error("Error removing project from scope:", err);
            dispatch({ type: 'REMOVE_PROJECT_SCOPE_ERROR', err });
        }
    };
};


export const addClientToScope = (userID, clientID) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Fetch client data from Firestore using the clientID
            const clientDocRef = firestore.collection('clients').doc(clientID);
            const clientDoc = await clientDocRef.get();
            if (!clientDoc.exists) {
                throw new Error(`Client with ID ${clientID} not found`);
            }

            // Reference to the user's document in Firestore
            const userDocRef = firestore.collection('users').doc(userID);

            // Fetch the current clientScope array
            const userDoc = await userDocRef.get();
            let currentClientScope = userDoc.data().clientScope || [];

            // Add the new client ID to the array
            currentClientScope = [...currentClientScope, clientID];

            // Fetch all client names from Firestore for sorting
            const clientDataPromises = currentClientScope.map(async (id) => {
                const doc = await firestore.collection('clients').doc(id).get();
                return { id, name: doc.data().name };
            });

            const clientsData = await Promise.all(clientDataPromises);

            // Sort the clientScope array based on the client names
            const sortedClientScope = clientsData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((client) => client.id);

            // Update the user's clientScope with the sorted array
            await userDocRef.update({
                clientScope: sortedClientScope
            });

            dispatch({ type: 'ADD_CLIENT_TO_SCOPE_SUCCESS', userID, clientID });

        } catch (err) {
            console.error("Error adding client to scope:", err);
            dispatch({ type: 'ADD_CLIENT_TO_SCOPE_ERROR', err });
        }
    };
};

export const addClientsToScope = (userID, clientIDs) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Fetch the current clientScope array from the user's document in Firestore
            const userDocRef = firestore.collection('users').doc(userID);
            const userDoc = await userDocRef.get();
            let currentClientScope = userDoc.data().clientScope || [];

            // Combine new client IDs with the existing clientScope
            const updatedClientScope = [...new Set([...currentClientScope, ...clientIDs])]; // Ensure no duplicates

            // Fetch all client documents using their IDs to sort by name
            const clientDataPromises = updatedClientScope.map(async (id) => {
                const clientDocRef = firestore.collection('clients').doc(id);
                const clientDoc = await clientDocRef.get();
                if (!clientDoc.exists) {
                    throw new Error(`Client with ID ${id} not found`);
                }
                return { id, name: clientDoc.data().name };
            });

            const clientsData = await Promise.all(clientDataPromises);

            // Sort the clientScope array based on client names
            const sortedClientScope = clientsData
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
                .map((client) => client.id); // Keep only the client IDs

            // Update the user's clientScope with the sorted array
            await userDocRef.update({
                clientScope: sortedClientScope
            });

            dispatch({ type: 'ADD_CLIENTS_TO_SCOPE_SUCCESS', userID, clientIDs });

        } catch (err) {
            console.error("Error adding clients to scope:", err);
            dispatch({ type: 'ADD_CLIENTS_TO_SCOPE_ERROR', err });
        }
    };
};




export const removeClientFromScope = (userID, clientID) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Reference to the user's document in Firestore
            const userDocRef = firestore.collection('users').doc(userID);

            // Remove the clientID from the clientScope array
            await userDocRef.update({
                clientScope: firestore.FieldValue.arrayRemove(clientID)
            });

            dispatch({ type: 'REMOVE_CLIENT_FROM_SCOPE_SUCCESS', userID, clientID });

        } catch (err) {
            console.error("Error removing client from scope:", err);
            dispatch({ type: 'REMOVE_CLIENT_FROM_SCOPE_ERROR', err });
        }
    };
};

export const updateRole = (userID, role) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({role: role})
        .then(() => {
            dispatch({ type: 'UPDATE_ROLE', userID });
        }).catch((err) => {
            dispatch({ type: 'UPDATE_ROLE_ERROR', err });
        })
    }
}

export const addUserLastTimeIn = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            lastSignIn: firestore.FieldValue.serverTimestamp() 
        }).then(() => {
            dispatch({ type: 'UPDATE_LAST_SIGNIN', userID });
        }).catch((err) => {
            dispatch({ type: 'UPDATE_LAST_SIGNIN_ERR', err });
        })
    }
}

export const reportABug = (userID, report) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        if (!report) {
            dispatch({ type: 'BUG_REPORT_ERR', err: "Report is empty"})
        } else {
            const firestore = getFirestore();
            firestore.collection('Reports').doc(uuidv4()).set({
                userID: userID, report: report, time: firestore.FieldValue.serverTimestamp(), resolved: false,
            }).then(() => {
                dispatch({ type: 'BUG_REPORT_SUCCESS', success: "Report successfully submitted!" });
            }).catch((err) => {
                dispatch({ type: 'BUG_REPORT_ERR', err });
            })
        }
    }
}

export const resolveBug = (reportID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('Reports').doc(reportID).update({
            resolved: true
        }).then(() => {
            dispatch({ type: 'BUG_RESOLVE_SUCCESS', reportID  });
        }).catch((err) => {
            dispatch({ type: 'BUG_RESOLVE_ERR', err });
        })
    }
}

export const checkin = (userID, weekOf) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            [`checkins.${weekOf}`] : true 
        }).then(() => {
            dispatch({ type: 'CHECKED_IN', userID });
        }).catch((err) => {
            dispatch({ type: 'CHECK_IN_ERR', err });
        })
    }
}

export const vaccineSet = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            vaccineStatus: true
        }).then(() => {
            dispatch({ type: 'VACCINE_UPDATED', userID });
        }).catch((err) => {
            dispatch({ type: 'VACCINE_UPDATE_ERR', err });
        })
    }
}

export const vaccineUndo = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            vaccineStatus: false
        }).then(() => {
            dispatch({ type: 'VACCINE_UNDO', userID });
        }).catch((err) => {
            dispatch({ type: 'VACCINE_UNDO_ERR', err });
        })
    }
}

export const homeMessageSeen = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            homeMessageSeen: true
        }).then(() => {
            dispatch({ type: 'HOME_MESSAGE_SEEN', userID });
        }).catch((err) => {
            dispatch({ type: 'HOME_MESSAGE_SEEN_ERR', err });
        })
    }
}

export const resetHomeMessageSeen = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            homeMessageSeen: false
        }).then(() => {
            dispatch({ type: 'HOME_MESSAGE_RESET', userID });
        }).catch((err) => {
            dispatch({ type: 'HOME_MESSAGE_RESET_ERR', err });
        })
    }
} 

export const noticesAccepted = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            noticesAccepted: true
        }).then(() => {
            dispatch({ type: 'NOTICES_ACCEPTED', userID });
        }).catch((err) => {
            dispatch({ type: 'NOTICES_ACCEPTED_ERR', err });
        })
    }
}

export const resetNoticesAccepted = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            noticesAccepted: false
        }).then(() => {
            console.log("worked")
            dispatch({ type: 'NOTICES_ACCEPTED_RESET', userID });
        }).catch((err) => {
            console.log("failed");
            dispatch({ type: 'NOTICES_ACCEPTED_RESET_ERR', err });
        })
    }
} 


export const resetNoticesAcceptedForAll = (userIDs) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const batch = firestore.batch();

        userIDs.forEach(userID => {
            const userRef = firestore.collection('users').doc(userID);
            batch.update(userRef, { noticesAccepted: false });
        });

        batch.commit().then(() => {
            dispatch({ type: 'NOTICES_ACCEPTED_RESET_ALL' });
            console.log("All user noticesAccepted reset successfully.");
        }).catch((err) => {
            dispatch({ type: 'NOTICES_ACCEPTED_RESET_ALL_ERR', err });
            console.log("Error resetting noticesAccepted for all users.");
        });
    };
};



export const updateTechInfo = (userID, field, value) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        if (!value) {
            dispatch({ type: 'UPDATE_VALUE_ERR', err: field })
        } else {
            firestore.collection('users').doc(userID).update({
                [`${field}`]: value
            }).then(() => {
                dispatch({ type: 'TECH_INFO_UPDATED', userID });
            }).catch((err) => {
                dispatch({ type: 'TECH_INFO_UPDATE_ERR', err });
            })
        }
    }
}

export const updateUserNotification = (userID, medium, type, value) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            [`notifications.${medium}.${type}`]: value
        }).then(() => {
            dispatch({ type: 'USER_NOTIFICATIONS_UDPATED', userID });
        }).catch((err) => {
            dispatch({ type: 'NOTIFICATIONS_UPDATED_ERR', err });
        })
    }
}

export const updateAdminTimestamp = (field, time) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection("SchedulerProjects").doc("administrative").update({
            [`${field}`]: time
        }).then(() => {
            dispatch({ type: 'TIMESTAMP_UPDATED', field });
        }).catch((err) => {
            dispatch({ type: 'TIMESTAMP_UPDATE_ERR', err });
        })
    }
}

export const addBartExpense = (refLocation, userID, toOrigin, toDestination, leaveOrigin, leaveDestination, toCost, leaveCost, pictureURL, projectDate, client) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const projectRef = refLocation;
        const expenseRef = projectRef.collection('Expenses').doc(uuidv4());
        expenseRef.set({
            type: "BART",
            userID,
            toOrigin,
            toDestination,
            leaveOrigin,
            leaveDestination,
            toCost,
            leaveCost,
            totalCost: toCost + leaveCost,
            pictureURL,
            approvedBy: '',
            refLocation: expenseRef,
            projectDate,
            createdAt: new Date(),
            client,
            status: 'pending',
        }).then(() => {
            dispatch({ type: 'ADD_BART_EXPENSE', refLocation });
        }).catch((err) => {
            dispatch({ type: 'ADD_BART_EXPENSE_ERR', err });
        })
    }
}

export const addParkingExpense = (refLocation, userID, garageName, garageAddress, garageCost, pictureURL, projectDate, client) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        console.log('func', projectDate)
        const projectRef = refLocation;
        const expenseRef = projectRef.collection('Expenses').doc(uuidv4());
        expenseRef.set({
            type: "Parking",
            userID,
            garageName,
            garageAddress,
            totalCost: garageCost,
            pictureURL,
            approvedBy: '',
            refLocation: expenseRef,
            projectDate,
            createdAt: new Date(),
            client,
            status: 'pending',
        }).then(() => {
            dispatch({ type: 'ADD_PARKING_EXPENSE', refLocation });
        }).catch((err) => {
            dispatch({ type: 'ADD_PARKING_EXPENSE_ERR', err });
        })
    }
}

export const approveReport = (refLocation) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            status: 'approved'
        }).then(() => {
            dispatch({ type: 'REPORT_APPROVED', refLocation });
        }).catch((err) => {
            dispatch({ type: 'REPORT_APPROVED_ERR', err });
        })
    }
}

export const denyReport = (refLocation, denyReason) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            status: 'denied',
            denyReason, 
        }).then(() => {
            dispatch({ type: 'REPORT_DENIED', refLocation });
        }).catch((err) => {
            dispatch({ type: 'REPORT_DENIED_ERR', err });
        })
    }
}

export const deleteReport = (refLocation) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            status: 'deleted'
        }).then(() => {
            dispatch({ type: 'REPORT_DELETED', refLocation });
        }).catch((err) => {
            dispatch({ type: 'REPORT_DELETED', err });
        })
    }
}

export const promoteUser = (userID, newRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection("users").doc(userID).update({
            role: newRole
        }).then(() => {
            dispatch({ type: 'USER_PROMOTED', newRole });
        }).catch((err) => {
            dispatch({ type: 'USER_PROMOTED_ERR', err });
        })
    }
}

export const demoteUser = (userID, newRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection("users").doc(userID).update({
            role: newRole
        }).then(() => {
            dispatch({ type: 'USER_DEMOTED', newRole });
        }).catch((err) => {
            dispatch({ type: 'USER_DEMOTED_ERR', err });
        })
    }
}

export const deactivateUser = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection("users").doc(userID).update({
            role: "deactivated"
        }).then(() => {
            dispatch({ type: 'USER_DEACTIVATED', userID });
        }).catch((err) => {
            dispatch({ type: 'USER_DEACTIVATED_ERR', err });
        })
    }
}

export const updateUserProjectHistoryCount = (userID, projectCount) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection("users").doc(userID).update({
            sixMonthProjectCount: projectCount
        }).then(() => {
            dispatch({ type: 'USER_DEACTIVATED', userID });
        }).catch((err) => {
            dispatch({ type: 'USER_DEACTIVATED_ERR', err });
        })
    }
}

export const addWriteup = (userID, writeups) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            writeups: writeups
        }).then(() => {
            dispatch({ type: 'ADD_WRITEUP', userID });
        }).catch((err) => {
            dispatch({ type: 'ADD_WRITEUP_ERR', err });
        })
    }
}

export const updateWriteupStatus = (userID, writeupID, newStatus) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            [`writeups.${writeupID}.status`] : newStatus
        }).then(() => {
            dispatch({ type: 'CHANGE_WRITEUP_STAUS', userID });
        }).catch((err) => {
            dispatch({ type: 'CHANGE_WRITEUP_STAUS_ERR', err });
        })
    }
}

export const updateCertifications = (userID, certifications) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            certifications : certifications
        }).then(() => {
            dispatch({ type: 'CHANGE_CERTIFICATIONS', userID });
        }).catch((err) => {
            dispatch({ type: 'CHANGE_CERTIFICATIONS_ERR', err });
        })
    }
}

export const updateExperience = (userID, experience) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            experience : experience
        }).then(() => {
            dispatch({ type: 'CHANGE_EXPERIENCE', userID });
        }).catch((err) => {
            dispatch({ type: 'CHANGE_EXPERIENCE_ERR', err });
        })
    }
}



/***** ONE TIME FUNCTIONS BEGIN *****/
export const updateUsersNotifications = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            notifications: 
                {email: {
                    newProjects: true, confirmedForProject: true, projectUpdated: true, supervisorComment: true, leadComment: true,
                }, phone: {
                    newProjects: false, confirmedForProject: false, projectUpdated: false, supervisorComment: false, leadComment: false,
                }}
        }).then(() => {
            dispatch({ type: 'NOTIFICATIONS_UDPATED', userID });
        }).catch((err) => {
            dispatch({ type: 'NOTIFICATIONS_UPDATED_ERR', err });
        })
    }
}

export const addWriteupsToAll = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            writeups: {}
        }).then(() => {
            dispatch({ type: 'NOTIFICATIONS_UDPATED', userID });
        }).catch((err) => {
            dispatch({ type: 'NOTIFICATIONS_UPDATED_ERR', err });
        })
    }
}

export const addCertsToAll = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            certifications: [],
        }).then(() => {
            dispatch({ type: 'NOTIFICATIONS_UDPATED', userID });
        }).catch((err) => {
            dispatch({ type: 'NOTIFICATIONS_UPDATED_ERR', err });
        })
    }
}

export const addExperienceToAll = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            experience: [],
        }).then(() => {
            dispatch({ type: 'NOTIFICATIONS_UDPATED', userID });
        }).catch((err) => {
            dispatch({ type: 'NOTIFICATIONS_UPDATED_ERR', err });
        })
    }
}

export const addResumeRefToAll = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            resumeRef: "",
        }).then(() => {
            dispatch({ type: 'NOTIFICATIONS_UDPATED', userID });
        }).catch((err) => {
            dispatch({ type: 'NOTIFICATIONS_UPDATED_ERR', err });
        })
    }
}
/***** ONE TIME FUNCTIONS END *****/