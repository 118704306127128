import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { NavLink } from 'react-router-dom';

import moment from 'moment';

import { addUserLastTimeIn } from '../../store/actions/projectActions';
import { updateUsersNotifications } from '../../store/actions/userActions';

import AssignRoles from './children/AssignRoles';
import BugReports from './children/BugReports';
import SearchUser from './children/SearchUser';
import SearchClient from './children/SearchClient';
import SearchSkill from './children/SearchSkill';
import VaccineStats from './children/VaccineStats';
import Checkins from './children/Checkins';
import WorkplaceNoticesManager from './children/WorkplaceNoticesManager';
import GatherReports from './children/GatherReports';
import './Manage.css'
import Loading from '../assets/Loading.gif'

class Manage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noNewUsers: true,
            email: '',
            twPhoneNumber: "+17145634375",
            phoneNumber: "+19258585064",
            showExpenseReport: true,
            expenseStartDate: null,
            expenseEndDate: null,
            unapprovedReports: {},
            show: false,
        }
    }

    render() {
        const { auth, users, clients, reports, projects } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />

        if (users && reports && projects) {
            var unvax = []

            Object.entries(users).forEach(entry => {
                const id = entry[0]
                const user = entry[1]
                if (user.role === "tech") {
                    if (user.vaccineStatus === null || user.vaccineStatus === false) {
                        unvax.push(user.firstName + " " + user.lastName + "               " + id)
                    }
                }
            })


            var projectsQuery = {};
            if (this.state.expenseStartDate && this.state.expenseEndDate) {
                // Gather all projects within the date range
                Object.entries(projects).forEach(entry => {
                    const id = entry[0]
                    const project = entry[1]
        
                    const projectStart = moment(project.startTime)
                    const startThreshold = moment(this.state.expenseStartDate)
                    const endThreshold = moment(this.state.expenseEndDate).add(1, 'day')
        
                    if (projectStart >= startThreshold && projectStart < endThreshold ) {
                        projectsQuery[id] = moment(project.startTime).startOf("month").format('YYYY MMMM');
                    }
                })
            } else {
                projectsQuery = projects;
            }

            var writeUpUserList = [];
            Object.entries(users).filter(Boolean).forEach(entry => {
                const id = entry[0];
                const user = entry[1];

                if (user.writeUps !== undefined) {
                    writeUpUserList.push(id)
                }
            })
            
            return (
                <Container>
                    <Row style={{marginTop: 20, marginBottom: 0}}>
                        <AssignRoles users={users} />
                    </Row>

                    <Row style={{marginTop: 20, marginBottom: 0}}>
                        <BugReports reports={reports} users={users}/>
                    </Row>

                    <Row style={{marginTop: 20, marginBottom: 0}}>
                        <SearchUser projects={projects} users={users}/>
                    </Row>

                    <Row style={{marginTop: 20, marginBottom: 0}}>
                        <SearchClient clients={clients}/>
                    </Row>

                    <Row style={{marginTop: 20, marginBottom: 0}}>
                        <SearchSkill projects={projects} users={users}/>
                    </Row>

                    <Row >
                        <Col
                            xs={12}
                            md={4}
                            className="first-column"
                            style={{ paddingLeft: 0, paddingRight: '15px', marginTop: 20, marginBottom: 0 }}  // No left padding for first column on large screens
                        >
                            <VaccineStats users={users} />
                        </Col>

                        <Col
                            xs={12}
                            md={4}
                            className="middle-column"
                            style={{ padding: '0 15px', marginTop: 20, marginBottom: 0 }}  // Middle column gets padding on both sides for large screens
                        >
                            <Checkins users={users} />
                        </Col>

                        <Col
                            xs={12}
                            md={4}
                            className="last-column"
                            style={{ paddingRight: 0, paddingLeft: '15px', marginTop: 20, marginBottom: 0 }}  // No right padding for last column on large screens
                        >
                            <WorkplaceNoticesManager users={users} />
                        </Col>
                    </Row>

                    <Row style={{marginTop: 20, marginBottom: 0}}>
                        <Card className="ManageCard">
                            <Card.Header as="h5">Expense Reports</Card.Header>
                            <Card.Body>
                                <GatherReports projects={projects} users={users}/>

                                <Row style={{marginBottom: 0}}>
                                    <a style={{marginLeft: 0}} href="https://docs.google.com/spreadsheets/d/1MkrNL98f98m6agP8yBRseqY49DXKeqySU9ycYGgliE8/edit#gid=0"> Google Sheets Link</a>
                                </Row>
                                
                                <Row style={{marginBottom: 0}}>
                                    <NavLink to={'/manage/approve'} className="btn blue lighten-1 white-text">
                                        Approve Reports
                                    </NavLink>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                    
                    <Row style={{marginTop: 20, marginBottom: 0}}>
                        <Card className="ManageCard">
                            <Card.Header as="h5">Email Center</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Send email blasts to techs who haven't been checking in
                                </Card.Text>
                                <Row style={{marginBottom: 0}}>
                                    <NavLink to={'/manage/emailcenter'} className="btn blue lighten-1 white-text">
                                        View
                                    </NavLink>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            )
        } else {
            return <img style={{marginTop: 20}}className="LoadingWheel" src={Loading} alt="loading..." />
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        users: state.firestore.data.users,
        clients: state.firestore.data.clients,
        reports: state.firestore.data.Reports,
        projects: {
            ...state.firestore.data.MonthProjects,
            ...state.firestore.data.NextMonthProjects,
            ...state.firestore.data.LastMonthProjects,
            ...state.firestore.data.TwoMonthsAgoProjects,
            ...state.firestore.data.ThreeMonthsAgoProjects,
            ...state.firestore.data.FourMonthsAgoProjects,
            ...state.firestore.data.FiveMonthsAgoProjects,
            ...state.firestore.data.SixMonthsAgoProjects,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addUserLastTimeIn: (userID) => dispatch(addUserLastTimeIn(userID)),
        updateUsersNotifications: (userID) => dispatch(updateUsersNotifications(userID)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection : 'SchedulerProjects', doc: moment().format('YYYY'),
            subcollections: [{ collection : moment().format('MMMM') }],
            storeAs: 'MonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().add(1, 'months').format('YYYY'),
            subcollections: [{ collection : moment().add(1, 'M').format('MMMM') }],
            storeAs: 'NextMonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(1, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(1, 'M').format('MMMM') }],
            storeAs: 'LastMonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(2, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(2, 'M').format('MMMM') }],
            storeAs: 'TwoMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(3, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(3, 'M').format('MMMM') }],
            storeAs: 'ThreeMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(4, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(4, 'M').format('MMMM') }],
            storeAs: 'FourMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(5, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(5, 'M').format('MMMM') }],
            storeAs: 'FiveMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(6, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(6, 'M').format('MMMM') }],
            storeAs: 'SixMonthsAgoProjects'
        },
        {
            collection: 'users',
        },
        {
            collection: "clients"
        },
        {
            collection: "Reports"
        }
    ])
)(Manage);


