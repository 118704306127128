import React from 'react';
import Card from 'react-bootstrap/Card';
import './UserInfoProfile.css';

class WorkplaceNotices extends React.Component {
  render() {
    const { noticesWithUrls } = this.props;

    // Return null if there are no notices to avoid rendering the card
    if (!noticesWithUrls || noticesWithUrls.length === 0) {
      return null;
    }

    return (
      <Card className="ProfileCard">
        <Card.Header as="h5">Workplace Notices</Card.Header>
        <Card.Body>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {noticesWithUrls.map((notice) => {
              const isPdf = notice.url && (notice.url.endsWith('.pdf') || notice.name.endsWith('.pdf'));
              
              return (
                <li key={notice.id || notice.name} style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
                    {isPdf ? (
                      <>
                        <iframe
                          src={notice.url}
                          style={{ width: '80%', height: '600px', border: 'none', display: 'block', margin: '0 auto' }}
                          frameBorder="0"
                          title={notice.name || 'PDF Notice'}
                        ></iframe>
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                          <a href={notice.url} target="_blank" rel="noopener noreferrer">
                            Open PDF in New Tab
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={notice.url}
                          alt={notice.name || 'Image Notice'}
                          style={{ maxWidth: '80%', height: 'auto', borderRadius: '8px' }}
                        />
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                          <a href={notice.url} target="_blank" rel="noopener noreferrer">
                            View Image in New Tab
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </Card.Body>
      </Card>
    );
  }
}

export default WorkplaceNotices;
