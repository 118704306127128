import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

const ConfirmationModal = ({ show, onHide, onConfirm, title, message, loading }) => (
    <Modal show={show} onHide={!loading ? onHide : null} centered>
        <Modal.Header closeButton={!loading}>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50px' }}>
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                message
            )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={onHide} disabled={loading}>Cancel</Button>
            <Button variant="primary" onClick={onConfirm} disabled={loading}>Confirm</Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;
