import React, { useState, useRef } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const FileUploadForm = ({ onFileUpload, uploadSuccess, uploadFailure, loading }) => {
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
            if (!allowedExtensions.includes(fileExtension)) {
                console.log('File type not supported. Please upload a PDF or an image.');
                return;
            }

            const uniqueFileName = `WorkplaceNotice_${uuidv4()}.${fileExtension}`;
            onFileUpload(file, uniqueFileName);  // Pass file with a unique name
            setFile(null);                       // Clear the file state
            fileInputRef.current.value = "";      // Clear the file input
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <Form.File
                    id="fileUpload"
                    label="Choose Workplace Notice (PDF/Image)"
                    onChange={handleChange}
                    accept=".pdf,.jpg,.jpeg,.png"
                    ref={fileInputRef}
                />
            </Form.Group>
            {file && (
                <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? 'Uploading...' : 'Upload Notice'}
                </Button>
            )}
            {uploadSuccess && <Alert variant="success">File uploaded successfully!</Alert>}
            {uploadFailure && <Alert variant="danger">File upload failed. Please try again.</Alert>}
        </Form>
    );
};

export default FileUploadForm;
