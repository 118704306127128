import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { storage } from '../../config/fbConfig';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import UserInfoProfileMobile from './UserInfoProfileMobile';
import ContactInfo from './ContactInfo';
import Certifications from './Certifications';
import Writeups from './Writeups/Writeups';
import WorkplaceNotices from './WorkplaceNotices';
import Loading from '../assets/Loading.gif';
import './UserInfoProfile.css';

class UserInfoProfile extends Component {
  state = {
    noticesWithUrls: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.notices !== this.props.notices) {
      this.loadFileUrls(this.props.notices);
    }
  }

  loadFileUrls = (notices) => {
    const loadUrlsForFiles = (files) => {
      return Promise.all(
        files.map((file) =>
          storage.ref(file.path).getDownloadURL().then((url) => ({ ...file, url }))
        )
      );
    };

    loadUrlsForFiles(notices).then((noticesWithUrls) =>
      this.setState({ noticesWithUrls })
    );
  };

  render() {
    const { auth, profile, users } = this.props;
    const { noticesWithUrls } = this.state;

    if (!auth.uid) return <Redirect to='/signin' />;

    if (window.innerWidth < 1200) {
      return <UserInfoProfileMobile auth={auth} profile={profile} users={users} noticesWithUrls={noticesWithUrls} />;
    } else if (auth && profile.firstName) {
      return (
        <Container>
          <Row>
            <ContactInfo profile={profile} />
          </Row>
          <Row>
            <Certifications auth={auth} profile={profile} />
          </Row>
          <Row>
            <Writeups auth={auth} profile={profile} users={users} />
          </Row>
          <Row>
            <WorkplaceNotices noticesWithUrls={noticesWithUrls} />
          </Row>
        </Container>
      );
    } else {
      return <img style={{ marginTop: 20 }} className="LoadingWheel" src={Loading} alt="loading..." />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    users: state.firestore.data.users,
    notices: state.firestore.ordered.publishedNotices, // Access notices data from Firestore
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(() => [
    {
      collection: 'notices',
      where: [['published', '==', true]], // Fetch only published notices
      storeAs: 'publishedNotices',
    },
    {
      collection: 'users',
    },
  ])
)(UserInfoProfile);