import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { storage } from '../../../../config/fbConfig';
import { getFirestore } from 'redux-firestore';
import { connect } from 'react-redux';
import FileUploadForm from './FileUploadForm';
import NoticesList from './NoticesList';
import ConfirmationModal from './ConfirmationModal';
import { resetNoticesAcceptedForAll } from '../../../../store/actions/userActions';

class WorkplaceNoticesManager extends Component {
    state = {
        unpublishedFiles: [],
        publishedFiles: [],

        showDeleteModal: false,
        showPublishModal: false,
        showUnpublishModal: false,

        fileToDelete: null,
        fileToPublish: null,
        fileToUnpublish: null,

        uploadSuccess: false,
        uploadFailure: false,

        uploading: false,

        deletingFileId: null,
        publishingFileId: null,
        unpublishingFileId: null,
    };

    componentDidMount() {
        this._isMounted = true; // Track if the component is mounted
        this.fetchFiles();
    }

    componentWillUnmount() {
        this._isMounted = false; // Cleanup on unmount. Prevent memory leak.
      }

    fetchFiles = () => {
        const firestore = getFirestore();
    
        const loadUrlsForFiles = (files) => {
            return Promise.all(
                files.map((file) =>
                    storage.ref(file.path).getDownloadURL().then((url) => ({ ...file, url }))
                )
            );
        };
    
        firestore
            .collection('notices')
            .where('published', '==', false)
            .get()
            .then((querySnapshot) => {
                if (this._isMounted) { // Check if component is still mounted
                    const unpublishedFiles = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    return loadUrlsForFiles(unpublishedFiles);
                }
            })
            .then((updatedUnpublished) => this.setState({ unpublishedFiles: updatedUnpublished }));
    
        firestore
            .collection('notices')
            .where('published', '==', true)
            .get()
            .then((querySnapshot) => {
                if (this._isMounted) {
                    const publishedFiles = querySnapshot.docs.map((doc) => ({
                        id: doc.id, // Save the document ID
                        ...doc.data()
                    }));
                    return loadUrlsForFiles(publishedFiles);
                }
            })
            .then((updatedPublished) => {
                if (this._isMounted) {
                    this.setState({ publishedFiles: updatedPublished });
                }
                });
    };
    
    handleFileUpload = (file, fileName) => {
        const storageRef = storage.ref(`/admin/notices/unpublished/${fileName}`);
        const firestore = getFirestore();
    
        this.setState({ uploading: true });
    
        storageRef.put(file).then(() => {
            firestore.collection('notices').add({
                name: fileName,
                path: `/admin/notices/unpublished/${fileName}`,
                published: false,
                timestamp: new Date(),
            });
            this.setState({ uploadSuccess: true, uploading: false });
            this.fetchFiles();
            setTimeout(() => this.setState({ uploadSuccess: false }), 3000);
        }).catch(() => {
            this.setState({ uploadFailure: true, uploading: false });
        });
    };
    
    handleDeleteFile = async () => {
        const { fileToDelete } = this.state;
        const firestore = getFirestore();
    
        if (!fileToDelete) {
            this.setState({ showDeleteModal: false });
            return;
        }
    
        this.setState({ deletingFileId: fileToDelete.id });
    
        const docRef = firestore.collection('notices').doc(fileToDelete.id);
    
        try {
            await storage.ref(fileToDelete.path).delete();
            await docRef.delete();
    
            console.log('File and Firestore record deleted successfully.');
    
            // Wait for fetchFiles to complete before closing the modal
            await this.fetchFiles();
    
            this.setState({
                showDeleteModal: false,
                fileToDelete: null,
                deletingFileId: null
            });
        } catch (error) {
            console.log('Error deleting file:', error);
            this.setState({ deletingFileId: null });
        }
    };
    
    
    handleMoveToUnpublished = async () => {
        const { fileToUnpublish } = this.state;
    
        if (!fileToUnpublish) {
            this.setState({ showUnpublishModal: false });
            return;
        }
    
        const publishedRef = storage.ref(fileToUnpublish.path);
        const unpublishedRef = storage.ref(`/admin/notices/unpublished/${fileToUnpublish.name}`);
        const firestore = getFirestore();
        
        this.setState({ unpublishingFileId: fileToUnpublish.id });
    
        try {
            const url = await publishedRef.getDownloadURL();
            const response = await fetch(url);
            const blob = await response.blob();
    
            await unpublishedRef.put(blob);
            await publishedRef.delete();
    
            const docRef = firestore.collection('notices').doc(fileToUnpublish.id);
            await docRef.update({
                path: `/admin/notices/unpublished/${fileToUnpublish.name}`,
                published: false
            });
    
            console.log('File moved to unpublished and Firestore record updated.');
    
            // Wait for fetchFiles to complete before closing the modal
            await this.fetchFiles();
    
            this.setState({
                showUnpublishModal: false,
                fileToUnpublish: null,
                unpublishingFileId: null
            });
        } catch (error) {
            console.error("Error moving file:", error);
            this.setState({ unpublishingFileId: null });
        }
    };
    
    
    handlePublishFile = async () => {
        const { fileToPublish } = this.state;
        const { resetNoticesAcceptedForAll, users } = this.props;

        if (!fileToPublish) {
            this.setState({ showPublishModal: false });
            return;  // Exit if no file is selected
        }

        const userIDs = Object.keys(users);
        const unpublishedRef = storage.ref(fileToPublish.path);
        const publishedRef = storage.ref(`/admin/notices/published/${fileToPublish.name}`);
        const firestore = getFirestore();

        this.setState({ publishingFileId: fileToPublish.id });

        try {
            const url = await unpublishedRef.getDownloadURL();
            const response = await fetch(url);
            const blob = await response.blob();
    
            await publishedRef.put(blob);
            await unpublishedRef.delete();
    
            const docRef = firestore.collection('notices').doc(fileToPublish.id);
            await docRef.update({
                path: `/admin/notices/published/${fileToPublish.name}`,
                published: true
            });
    
            resetNoticesAcceptedForAll(userIDs);
    
            // Start fetchFiles and wait for it to complete before closing the modal
            await this.fetchFiles();
    
            // Now close the modal after everything is done
            this.setState({
                fileToPublish: null,
                publishingFileId: null,
                showPublishModal: false
            });
        } catch (error) {
            console.error("Error publishing file:", error);
            this.setState({ publishingFileId: null });
        }
    };

    render() {
        const {
            unpublishedFiles,
            publishedFiles,
            showDeleteModal,
            showPublishModal,
            showUnpublishModal,
            uploadSuccess,
            uploadFailure,
            uploading,
            deletingFileId,
            publishingFileId,
            unpublishingFileId
        } = this.state;

        return (
            <Card className="ManageCard">
                <Card.Header as="h5">Workplace Notices</Card.Header>
                <Card.Body>
                    <FileUploadForm
                        onFileUpload={this.handleFileUpload}
                        uploadSuccess={uploadSuccess}
                        uploadFailure={uploadFailure}
                        loading={uploading}
                    />
                    <NoticesList
                        unpublishedFiles={unpublishedFiles}
                        publishedFiles={publishedFiles}
                        onDelete={(file) => this.setState({ showDeleteModal: true, fileToDelete: file })}
                        onPublish={(file) => this.setState({ showPublishModal: true, fileToPublish: file })}
                        onUnpublish={(file) => this.setState({ showUnpublishModal: true, fileToUnpublish: file })}
                        deletingFileId={deletingFileId}
                        publishingFileId={publishingFileId}
                        unpublishingFileId={unpublishingFileId}
                    />
                    <ConfirmationModal
                        show={showDeleteModal}
                        onHide={() => this.setState({ showDeleteModal: false })}
                        onConfirm={this.handleDeleteFile}
                        title="Delete Notice"
                        message="Are you sure you want to delete this notice? This action cannot be undone."
                        loading={deletingFileId !== null}
                    />
                    <ConfirmationModal
                        show={showPublishModal}
                        onHide={() => this.setState({ showPublishModal: false })}
                        onConfirm={this.handlePublishFile}
                        title="Publish Notice"
                        message="By publishing this notice, all users will be required to read and acknowledge it upon their next login. Are you sure you want to publish this notice?"
                        loading={publishingFileId !== null}
                    />
                    <ConfirmationModal
                        show={showUnpublishModal}
                        onHide={() => this.setState({ showUnpublishModal: false })}
                        onConfirm={this.handleMoveToUnpublished}
                        title="Unpublish Notice"
                        message="Are you sure you want to unpublish this notice?"
                        loading={unpublishingFileId !== null}
                    />
                </Card.Body>
            </Card>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetNoticesAcceptedForAll: (userIDs) => dispatch(resetNoticesAcceptedForAll(userIDs))
});

export default connect(null, mapDispatchToProps)(WorkplaceNoticesManager);
